#News .card a {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

#News .card a:hover,
#News .card a:focus {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

#News .card p {
  color: rgba(0, 0, 0, 0.5);
}
